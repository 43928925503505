.layout {
  height: 100%;
}

.layout-content {
  padding: '0 50px';
}

.site-layout-content {
  height: 100%;
  padding: 24px;
  background: #fff;
}

.site-layout-content-no-padding {
  height: 100%;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
