.input {
  border: none !important;
  padding: 1px 1px 1px 5px;
}

.input:hover {
  border: 1px solid #d9d9d9 !important;
}

.input:focus {
  border: 1px solid #0068a4 !important;
}

.btn {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.dropdown {
  border: none !important;

  .icon {
    display: none !important;
  }
}

.dropdown:hover,
.dropdown:focus {
  border: 1px solid #1c7fb0 !important;

  .icon {
    display: block !important;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  background: none !important;
  color: unset;
}

.ant-btn[disabled].dropdown:hover,
.ant-btn[disabled].dropdown:focus {
  border: none !important;
  cursor: unset;

  .icon {
    display: none !important;
  }
}

.datepicker {
  border: none;

  .ant-picker-clear {
    display: none;
  }

  .ant-picker-suffix {
    display: none;
  }
}

.datepicker:focus,
.datepicker:hover {
  border: 1px solid #1c7fb0 !important;

  .ant-picker-clear {
    display: block;
  }

  .ant-picker-suffix {
    display: block;
  }
}

.datepicker,
.input,
.dropdown {
  background: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
