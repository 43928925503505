.tabs {
  display: flex;
  align-items: center;
}

.editable_cell_error {
  border: solid 1px #ff4d4f !important;
}

.editable_cell_warning {
  border: solid 1px #f0ad4e !important;
}

.tutorial-popover {
  position: absolute;
  right: 24px;
  z-index: 10;
  color: #48a84c;
}

.mailSendDates {
  display: flex;
  flex-direction: column;
}
