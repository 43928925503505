.pdf_viewer {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.nav {
  display: flex;
  align-items: center;
  padding-left: 12em;
  padding-right: 12em;
  cursor: pointer;

  :hover {
    color: #0068a4;
  }
}

.nav-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.canvas {
  display: flex;
  align-items: center;
}
