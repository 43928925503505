.container {
  height: 100%;
}

h3 {
  color: #666;
}

.login_panel {
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0 2% 0 2%;
  text-align: center;
  flex: 1;
}

.login_logo {
  width: 261px;
  height: 123px;
  margin-top: 40px;
  margin-bottom: 60px;
}

.panel_bottom {
  margin-top: auto;
  margin-bottom: 40px;

  h3 {
    margin-bottom: 40px;
  }

  .btn {
    width: 90%;
    height: 64px;
    font-weight: 800;
  }
}

.login_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
