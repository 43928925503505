body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* PAGE  */
.page_title {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px;
}

/* TABS */
.tabs_title > {
  color: #0068a4;
}

/* BUTTON  */
.btn-table {
  margin: 0 5px;
}

/* FORM */
.error {
  color: #ff4d4f;
}

.input_error {
  border: 2px solid #ff4d4f !important;
  border-radius: 2px;
  outline-color: #ff4d4f;
}

/* POPOVER */
.ant-popover-title {
  color: #0068a4 !important;
}

/* GLOBAL  */
.btn-icon-text > svg, .page_title > h2 > svg, .tabs_title > svg {
  margin: 0 10px 0 0;
}

.initial_container {
  position: relative;
  text-align: center;
  color: white;
}

.initial_centered {
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  line-height: 0;
}