.logo_div {
  float: left;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 2px 30px;
}

.logo_div > img {
  width: 80px;
  margin-bottom: 2px;
}

.menu-item-right {
  float: right;
}

// RIGHT PANEL
.menu-item-right-svg {
  margin-left: 5px;
}

.menu-item-right-img {
  width: 40px;
}

.item-right-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullname_container {
  margin-left: 5px;
}
